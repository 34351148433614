import React from 'react';
import { Button } from './base';
import { RollbackOutlined, EditOutlined } from '@ant-design/icons';

const EditButton = ({ editable, onEdit, onCancel, cancelText, editText, showAcceptRejectAll, acceptRejectAllText, onAcceptRejectAll, showLatestEBookingText, onShowLatestEbooking }) => (
    editable ?
        <><Button icon={<RollbackOutlined />} onClick={onCancel}>{cancelText}</Button> {showAcceptRejectAll ? <div><Button type="primary" icon={<EditOutlined />} onClick={onAcceptRejectAll}>{acceptRejectAllText}</Button><Button type="primary" style={{ marginLeft: '1em' }} onClick={onShowLatestEbooking}>{showLatestEBookingText}</Button></div> : ''}</>
        :
        <Button type="primary" icon={<EditOutlined />} onClick={onEdit}>{editText}</Button>
);

EditButton.defaultProps = {
    editable: false,
    cancelText: 'Cancel',
    editText: 'Edit'
};

export default EditButton;
